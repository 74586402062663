.service {
	margin-top: 48px;
}
.service-title {
	color: rgb(0, 0, 0);
	font-family: 'Manrope', sans-serif;
	font-size: 40px;
	font-weight: 600;
	line-height: 64px;
	letter-spacing: 0%;
	text-align: center;
}
.service-title-desc {
	color: rgb(51, 51, 51);
	font-family: 'Manrope', sans-serif;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: 0%;
	text-align: center;
	margin-bottom: 40px;
}

.service-card-img-box {
	width: 64px;
	height: 64px;
	border-radius: 32px;
	background: rgb(231, 231, 231);
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
	margin-bottom: 16px;
}

.service-card-title {
	color: rgb(0, 0, 0);
	font-family: 'Manrope', sans-serif;
	font-size: 16px;
	font-weight: 600;
	line-height: 32px;
	letter-spacing: 0%;
	text-align: center;
	margin-bottom: 16px;
}

.service-card-desc {
	color: rgb(0, 0, 0);
	font-family: 'Manrope', sans-serif;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: 0%;
	text-align: center;
	padding: 0 50px;
}
