.hero {
	height: 600px;
	padding: 32px;
	border-radius: 24px;
	background-size: cover;
	background-repeat: no-repeat;
}

.hero.slide1 {
	background-image: url(/public/img/vietnam.webp);
	background-size: cover;
	background-attachment: fixed;
}
.hero.slide2 {
	background-image: url(/public/img/bali.webp);
	background-size: cover;
	background-attachment: fixed;
}
.hero.slide3 {
	background-image: url(/public/img/amsterdam.webp);
	background-size: cover;
	background-attachment: fixed;
}

.carouselExampleIndicators {
	height: 312px;
}

.hero-content {
	border-radius: 16px;
	backdrop-filter: blur(16px);
	/* background: rgba(0, 75, 68, 0.5); */
	background: rgba(36, 73, 128, 0.2);
	width: 63%;
	height: 312px;
	padding: 32px;
}
.hero-content h1 {
	color: rgb(255, 255, 255);
	font-family: 'Manrope', sans-serif;
	font-size: 48px;
	font-weight: 700;
	line-height: 88px;
	letter-spacing: 0%;
	text-align: left;
}
.hero-content p {
	color: rgb(255, 255, 255);
	font-family: 'Manrope', sans-serif;
	font-size: 24px;
	font-weight: 500;
	line-height: 48px;
	letter-spacing: 0%;
	text-align: left;
}

.hero-content-btn {
	margin-top: 32px;
	border-radius: 44px;
	background: rgb(255, 255, 255);
	padding: 16px 32px;
	outline: none;
	border: none;
}
.hero-content-btn a {
	text-decoration: none;
	color: #244980;
	font-family: 'Manrope', sans-serif;
	font-size: 16px;
	font-weight: 600;
	line-height: 24px;
	letter-spacing: 0%;
	text-align: left;
}

.hero-content-btn:hover .btn-arrow-icon svg {
	animation: arrowBtnAnimation 1s infinite;
}

@keyframes arrowBtnAnimation {
	0% {
		transform: translateX(0px);
	}
	100% {
		transform: translateX(10px);
	}
}

@media screen and (max-width: 1400px) {
	.hero-content h1 {
		font-size: 40px;
		line-height: 68px;
	}
}
@media screen and (max-width: 992px) {
	.hero-content h1 {
		font-size: 32px;
		line-height: 56px;
	}
}
@media screen and (max-width: 768px) {
	.hero {
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: space-between;
	}
	.hero-content {
		height: 220px;
		width: 100%;
	}
	.hero-content h1 {
		font-size: 24px;
		line-height: 36px;
	}
	.hero-content p {
		font-size: 14px;
		line-height: 24px;
	}
	.hero-content-btn {
		width: 100%;
		margin-bottom: 40px;
	}
}
