.language-selector {
	border: none;
	outline: none;
	color: rgb(0, 0, 0);
	font-family: 'Manrope', sans-serif;
	font-size: 18px;
	font-weight: 500;
	line-height: 48px;
	letter-spacing: 0%;
	text-align: left;
	background: transparent;
	cursor: pointer;
}

.language-selector .language-select-option {
	color: rgb(0, 0, 0);
	font-family: 'Manrope', sans-serif;
	font-size: 18px;
	font-weight: 500;
	line-height: 48px;
	letter-spacing: 0%;
	text-align: left;
	cursor: pointer;
}
