.contact {
	background: rgb(234, 234, 234);
}

.contact-title {
	color: rgb(0, 0, 0);
	font-family: 'Manrope', sans-serif;
	font-size: 40px;
	font-weight: 600;
	line-height: 64px;
	letter-spacing: 0%;
	text-align: center;
	margin-bottom: 48px;
}

.contact input,
.contact textarea {
	color: rgb(0, 0, 0);
	font-family: 'Manrope', sans-serif;
	font-size: 16px;
	font-weight: 600;
	line-height: 32px;
	letter-spacing: 0%;
	text-align: left;
}

.contact-btn {
	box-sizing: border-box;
	border: 1px solid rgb(190, 190, 190);
	border-radius: 8px;
	background: #244980;
	color: rgb(255, 255, 255);
	font-family: 'Manrope', sans-serif;
	font-size: 18px;
	font-weight: 600;
	line-height: 32px;
	letter-spacing: 0%;
	text-align: center;
}
.contact-btn:hover {
	background: rgba(36, 73, 128, 0.9);
	color: #fff;
}
