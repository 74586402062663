.travel {
	background-color: #244980;
	margin-top: 48px;
	padding: 67px 48px;
}

.travel-desc-box {
	padding-left: 40px;
}

.travel-title {
	max-width: 750px;
	color: rgb(255, 255, 255);
	font-family: 'Manrope', sans-serif;
	font-size: 48px;
	font-weight: 600;
	line-height: 72px;
	letter-spacing: 0%;
	text-align: left;
	margin-bottom: 48px;
}

.travel-desc {
	color: rgba(255, 255, 255, 0.75);
	font-family: 'Manrope', sans-serif;
	font-size: 24px;
	font-weight: 500;
	line-height: 40px;
	letter-spacing: 0%;
	text-align: left;
}
.travel-img-box img {
	border-radius: 20px;
}

@media screen and (max-width: 600px) {
	.travel {
		padding: 67px 0px;
	}
	.travel-desc-box {
		padding: 0 calc(var(--bs-gutter-x) * 0.5);
	}
	.travel-title {
		font-size: 36px;
		line-height: 42px;
		margin-bottom: 24px;
		text-align: center;
	}
	.travel-desc {
		font-size: 18px;
		line-height: 24px;
		text-align: center;
	}
}
