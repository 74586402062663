.tickets-title {
	color: rgb(0, 0, 0);
	font-family: 'Manrope', sans-serif;
	font-size: 40px;
	font-weight: 600;
	line-height: 64px;
	letter-spacing: 0%;
	text-align: left;
	margin: 48px 0;
}

.tickets-card {
	box-sizing: border-box;
	border: 1px solid rgb(217, 217, 217);
	border-radius: 16px;
	background: rgb(255, 255, 255);
	cursor: pointer;
}

.tickets-card-img {
	margin-bottom: 24px;
	border-radius: 16px;
	overflow: hidden;
	transition: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.tickets-card:hover .tickets-card-img img {
	transform: scale(1.1);
}

.tickets-card-desc {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.tickets-card-title {
	color: rgb(0, 0, 0);
	font-family: 'Manrope', sans-serif;
	font-size: 16px;
	font-weight: 600;
	line-height: 32px;
	letter-spacing: 0%;
	text-align: left;
	padding: 0 24px;
	margin-bottom: 8px;
	display: flex;
	align-items: center;
}

.location.img {
	width: 20px;
	height: 20px;
}

.tickets-card-price {
	font-family: 'Manrope', sans-serif;
	font-size: 16px;
	font-weight: 400;
	line-height: 32px;
	letter-spacing: 0%;
	text-align: left;
	padding: 0 24px;
	margin-bottom: 8px;
}

.tickets-card-btn {
	width: calc(100% - 48px);
	margin: 0 24px 24px;
	padding: 10px 15px;
	border: 1px solid #000;
	border-radius: 16px;
	background-color: #fff;
}

.tickets-card-btn a {
	text-decoration: none;
	color: #000;
}

.tickets-card:hover,
.tickets-card:hover .tickets-card-btn {
	border-color: #244980;
}

.tickets-card:hover .tickets-card-title,
.tickets-card:hover .tickets-card-desc {
	color: #244980;
}

.tickets-card:hover .tickets-card-btn {
	background-color: rgba(0, 75, 67, 0.1);
}

.tickets-card:hover .tickets-card-btn a {
	color: #244980;
}

.tickets-card:hover .tickets-card-btn img {
	animation: arrowBtnAnimation 1s infinite;
}

@keyframes arrowBtnAnimation {
	0% {
		transform: translateX(0px);
	}
	100% {
		transform: translateX(10px);
	}
}
